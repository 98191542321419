<!-- eslint-disable vue/no-unused-components -->
<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col>
        <div
          class="d-flex align-items-start justify-content-start"
          style="gap: 12px"
        >
          <a href="user-army/add" class="btn btn-info text-white" type="button"
            ><i class="fa fa-plus"></i> Add User</a
          >
          <b-dropdown
            id="dropdown-dropdown"
            dropdown
            text="Export"
            variant="success"
            v-if="$can('export_user_army')"
          >
            <b-dropdown-item @click="handleExport('All')">All</b-dropdown-item>
            <b-dropdown-item @click="handleExport('level_up')"
              >Level Up Only</b-dropdown-item
            >
            <b-dropdown-item @click="handleExport('maintenance')"
              >Maintenance Only</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12">
        <div>
          <b-form-group class="mb-3" label="Search :" label-for="search">
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Name, Email"
                @keyup.enter="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  type="button"
                  @click="resetFilter"
                >
                  reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="dateRange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="dateRange"
                :start="startDate"
                :end="endDate"
                @picker="doDateFilter"
              ></range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group class="mb-3" label="Search By Title" label-for="st">
            <b-form-select
              id="st"
              v-model="filterTitle"
              :options="titleOptions"
              @change="doFilterSelect('title', filterTitle)"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search by Level Up Status"
            label-for="sl"
          >
            <b-form-select
              id="sl"
              v-model="filterLevelUp"
              :options="levelUpOptions"
              @change="doFilterSelect('level_up_status', filterLevelUp)"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search by Maintenance Status"
            label-for="sm"
          >
            <b-form-select
              id="sm"
              v-model="filterMaintenance"
              :options="maintenanceOptions"
              @change="doFilterSelect('maintenance_status', filterMaintenance)"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="date-slot" slot-scope="prop">
        <span>{{ prop.rowData.created_at }}</span>
      </template>
      <template slot="user-slot" slot-scope="prop">
        <router-link
          :to="onAction('view-user', prop.rowData, prop.rowIndex)"
          target="_blank"
          >{{ prop.rowData.users.name }} ({{
            prop.rowData.users.email
          }})</router-link
        >
      </template>
      <template slot="title-slot" slot-scope="prop">
        <span>{{ prop.rowData.title }}</span>
      </template>
      <template slot="levelup-startDate" slot-scope="prop">
        <span>{{ prop.rowData.level_up_start_date }}</span>
      </template>
      <template slot="levelup-deadline" slot-scope="prop">
        <span>{{ prop.rowData.level_up_end_date }}</span>
      </template>
      <template slot="maintenanceDeadline-slot" slot-scope="prop">
        <span>{{ prop.rowData.maintenance_end_date }}</span>
      </template>
      <template slot="benefit-slot" slot-scope="prop">
        <span>{{ prop.rowData.current_treasure }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div
          class="custom-actions d-flex align-items-center justify-content-center"
          style="gap: 12px;"
        >
          <button
            class="btn btn-primary"
            v-if="$can('detail_user_army')"
            @click="onAction('detail-item', prop.rowData)"
          >
            <i class="fa fa-eye" aria-hidden="true"></i>
          </button>
          <button
            class="btn btn-warning"
            v-if="$can('detail_user_army')"
            @click="onAction('edit-item', prop.rowData)"
          >
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
      <vuetable-pagination
        ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import moment from "moment";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      filterTitle: "",
      filterText: "",
      filterLevelUp: "",
      filterMaintenance: "",
      isLoading: false,
      titleOptions: [
        { value: "", text: "Choose Title" },
        { value: "0", text: "Civillian" },
        { value: "1", text: "Knight" },
        { value: "2", text: "Knight Lieutenant" },
        { value: "3", text: "Knight Captain" },
        { value: "4", text: "Knight Commander" }
      ],
      levelUpOptions: [
        { value: "", text: "Select Level Up Status" },
        { value: "0", text: "Tidak Berpartisipasi" },
        { value: "1", text: "Dalam Proses" },
        { value: "2", text: "Terpenuhi" },
        { value: "3", text: "Gagal" }
      ],
      maintenanceOptions: [
        { value: "", text: "Select Maintenance Status" },
        { value: "0", text: "Tidak Berpartisipasi" },
        { value: "1", text: "Dalam Proses" },
        { value: "2", text: "Terpenuhi" },
        { value: "3", text: "Gagal" }
      ],
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `army/users`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sortOrder: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "date-slot",
          sortField: "created_at",
          title: "Register Army Date"
        },
        {
          name: "user-slot",
          sortField: "users.name",
          title: "User"
        },
        {
          name: "title-slot",
          sortField: "title",
          title: "Title"
        },
        {
          name: "level_up_program",
          title: "Level Up",
          sortField: "level_up_program",
          formatter: this.formaterProgram
        },
        {
          name: "levelup-startDate",
          title: "Level Up Start Date",
          sortField: "level_up_start_date"
        },
        {
          name: "levelup-deadline",
          title: "Level Up Deadline"
        },
        {
          name: "maintenance_program",
          title: "Maintenance",
          sortField: "maintenance_program",
          formatter: this.formaterProgram
        },
        {
          name: "maintenanceDeadline-slot",
          title: "Maintenance Deadline"
        },
        {
          name: "benefit-slot",
          title: "Current Treasure",
          sortField: "current_treasure"
        },
        {
          name: "actions-slot",
          title: "Action",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ]
    };
  },
  methods: {
    formaterProgram(value) {
      if (value == 0) {
        return '<font class="text-gray">Tidak Berpartisipasi</font>';
      } else if (value == 1) {
        return "<font>Dalam Proses</font>";
      } else if (value == 2) {
        return '<font class="text-green">Terpenuhi</font>';
      } else if (value == 3) {
        return '<font class="text-red">Gagal</font>';
      } else {
        return "<span> - </span>";
      }
    },
    onAction(action, data) {
      if (action == "view-user") {
        return { name: "Detail End User", params: { id: btoa(data.users.id) } };
      } else if (action == "detail-item") {
        const url = this.$router.resolve({
          name: "User Army Detail",
          params: { id: btoa(data.users.id) }
        }).href;
        window.open(url, "_blank");
      } else {
        const url = this.$router.resolve({
          name: "Edit User Army",
          params: { id: btoa(data.users.id) }
        }).href;
        window.open(url, "_blank");
      }
    },
    doFilter() {
      this.$events.$emit("filter-set", this.filterText);
    },
    doFilterSelect(type, value) {
      this.$events.$emit("select-set", type, value);
    },
    resetFilter() {
      this.filterText = "";
      this.$events.$emit("filter-reset");
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onFilterSet(filterText) {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "search";
        })
      );

      this.moreParams = {
        ...newParams,
        search: encodeURIComponent(filterText)
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "start_from" && key !== "end_to";
        })
      );

      this.moreParams = {
        ...newParams,
        start_from: this.startDate,
        end_to: this.endDate
      };
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "search";
        })
      );
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onDateFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "start_from" && key !== "end_to";
        })
      );
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    onFilterSelectSet(type, value) {
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== type;
        })
      );

      this.moreParams = value
        ? {
            ...newParams,
            [type]: value
          }
        : newParams;

      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    stringifyParams(params) {
      const filteredKeys = Object.keys(params).filter(key => {
        const value = params[key];
        return value !== "" && value !== null && value !== undefined;
      });

      if (filteredKeys.length === 0) {
        return "";
      } else if (filteredKeys.length === 1) {
        const key = filteredKeys[0];
        return `?${key}=${params[key]}`;
      } else {
        return `?${filteredKeys.map(key => `${key}=${params[key]}`).join("&")}`;
      }
    },
    async handleExport(type) {
      const currentDate = moment(this.$setDate);
      const sortField = `${this.$refs.vuetable.sortOrder[0].sortField}|${this.$refs.vuetable.sortOrder[0].direction}`;

      if (
        (type === "level_up" && "maintenance_status" in this.moreParams) ||
        (type === "maintenance" && "level_up_status" in this.moreParams)
      ) {
        this.$swal
          .fire(
            "Warning!",
            "Pilih export file sesuai dengan jenisnya",
            "warning"
          )
          .then(() => {
            return;
          });
      } else {
        this.isLoading = true;

        if (this.startDate !== "" && this.endDate !== "") {
          startDate = this.startDate;
          endDate = this.endDate;
        } else {
          endDate = this.$setDate.format("YYYY-MM-DD");
          startDate = currentDate.subtract(6, "days").format("YYYY-MM-DD");
        }

        const payload = {
          ...Object.fromEntries(
            Object.entries(this.moreParams).filter(([key]) => {
              return key !== "start_from" || key !== "end_to";
            })
          ),
          start_from: startDate,
          end_to: endDate,
          sort: sortField,
          export_type: type
        };
        const newParams = await this.stringifyParams(payload);

        this.$http
          .get(`army-users${newParams}`)
          .then(result => {
            this.isLoading = false;
            const exportPath = result.data.meta.data;
            window.location.href = exportPath;
          })
          .catch(error => {
            if (error.response) {
              this.isLoading = false;
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          });
      }
    },
    getDaysDifference(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      // Menghitung selisih dalam milisekon
      const difference = Math.abs(end - start);

      // Mengubah milisekon menjadi hari
      const daysDifference = Math.ceil(difference / (1000 * 60 * 60 * 24));

      return daysDifference;
    }
  },
  mounted() {
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on("select-set", (type, eventData) =>
      this.onFilterSelectSet(type, eventData)
    );
  }
};
</script>

<style>
.text-gray {
  color: rgb(128, 128, 128);
}
.text-red {
  color: #cb211f;
}
.text-green {
  color: #35aa4b;
}
.text-warning {
  color: #a78f27;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss"></style>
